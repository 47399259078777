import Registration from "../components/HomePage/RegistrationRequest/RegistrationData";
import { createHttpClient, createSecureHttpClient } from "../utils/HttpClient";
import { AppAccessRequest } from "../components/HomePage/RegistrationRequest/AppAccessRequestUtils";

export const registerUser = async (data: Registration) => {
  return createHttpClient().post<Registration>("/api/newuserrequests", data);
};

export const registerAppAccessRequests = async (
  token: string,
  data: AppAccessRequest
) => {
  return createSecureHttpClient(token).post<AppAccessRequest>(
    "/api/app-requests",
    data
  );
};
